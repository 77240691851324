import { Component, Renderer2 } from '@angular/core';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent {

  public paymentGateway:any='';
  public displayStyle: string = 'none';
  public paymentData:any;
  public user:any;
  public isAdminLoggedIn:any
  constructor(private service: CommonService, private renderer: Renderer2) {
  }
  ngOnInit(): void {

    
    if(this.service.isBrowserPlateformId()){ 
       // Dynamically load JS
       const script = this.renderer.createElement('script');
       script.src = 'https://sandbox.taluspayconnect.com/tokenizer/tokenizer.js';
       script.onload = () => {
         console.log('Swiper script loaded');
       };
       this.renderer.appendChild(document.body, script);

      this.user = JSON.parse(`${localStorage.getItem('user')}`)
    
    this.isAdminLoggedIn = this.user.isAdminLoggedIn
    this.service.paymentType.subscribe((res:any) => {
      this.paymentData = res;
      const elemnet = document.querySelector('#container');
      while (elemnet && elemnet.firstChild) {
        elemnet.removeChild(elemnet.firstChild);
      }
      this.initPyament()
    })
    }
  }
  getUserById() {
    // this.service.showSpinner();
    this.service.getApi(`users/get-user/${this.user.id}`).subscribe((res: any) => {
      if (res.status == 200) {
        this.user = res.user;
        // this.service.hideSpinner()
      }
      else {
        this.service.hideSpinner();
        this.service.showError(res.message);
      }
    }, (error) => {
      this.service.hideSpinner()
    })
  }


  initPyament() {
    this.service.showSpinner();
    if(this.service.isBrowserPlateformId()){
      this.paymentGateway = new (window as any).Tokenizer({
        url: 'https://app.taluspayconnect.com', //for production
        // url: 'https://sandbox.taluspayconnect.com', //for local
        apikey: 'pub_2W0xglhpOtdWNtUr90hZPOpbyla', // for production
        // apikey:'pub_2XJFffbkv3ZMMg9M1YJ2YkGbJuK', // for local
        container: '#container',
        submission: (resp: any) => { 
          this.paymentData.token = resp.token;
          this.createPayment();
         },
        onLoad: () => { this.service.hideSpinner() },
        settings: {
          styles: {
            'input, select': {
              'height': '45px',
              'font-size': '18px'
            },
            '.card .cc .cc-icon' :{
              color:'#004AAD'
            }
          },
          payment: {
            types: [this.paymentData.type], // Default ['card']
            ach: {
              sec_code: 'web',
              showSecCode: false, // Default false - true to show sec code dropdown
              verifyAccountRouting: false, // Default false - true to verify account routing numbers
  
              user_required: {
                first_name: false,
                last_name: false,
                email: false,
                phone: false,
              },
      
            },
            card: {
              requireCVV: true, // Default false - true to require cvv
            }
          }
        }
      });
    }
    this.displayStyle = 'flex';
    this.getUserById()

  }

  createPayment(){
    this.service.showSpinner();
    const data={
      amount: this.paymentData.amount,
      user:this.user,
      token: this.paymentData.token,
      credit: this.paymentData.credit,
      paymentType: this.paymentData.type,
      amountPerCredit:this.paymentData.amountPerCredit,
      isAdminLoggedIn:this.isAdminLoggedIn
    }
    this.service.postApi('payments/create-payment',data).subscribe((res:any)=>{
      if(res.status ==200){
        this.service.walletData.next(res)
        this.service.showSuccess(res.message);
      }
      else{
        this.service.showError(res.message);
      }
      if (this.service.isBrowserPlateformId()) {
  
        document.body.style.overflow = 'initial';
      }
      this.displayStyle = 'none';
      this.service.hideSpinner();
    },(error)=>{
      this.displayStyle = 'none';
      this.service.showError('Something went wrong')
      this.service.hideSpinner();
    })
  }
  submitPayment() {
    this.paymentGateway.submit();
  }
}
