import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent {
  public forgotForm : FormGroup;

  constructor(private service : CommonService) { 

    this.forgotForm = new FormGroup({
      email : new FormControl('',[Validators.required, Validators.pattern('^([a-zA-Z0-9_.\-]+)@([a-zA-Z0-9_.\-]+)[.]([a-zA-Z]{2,5})$'), Validators.maxLength(255)]),
    }
    )
  }

  ngOnInit(): void {
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.service.scrollToTop()
  }

  get f(){
    return this.forgotForm.controls
  }
  sendEmail(){
    let url = "users/forgot-password"
    let data = {
      email:this.forgotForm.value.email,
      webUrl:`https://kargone.com/`+'reset-password'
    }
    this.service.showSpinner()
    
    this.service.postApi(url,data).subscribe((res:any)=>{
      if(res.status==200){
        this.service.hideSpinner()
        this.service.showSuccess(res.message)
      }
      else{
        this.service.hideSpinner()
        this.service.showError(res['message'])
      }
    },(error)=>{
      this.service.hideSpinner()
        this.service.showError('Something went wrong')
    })
    
  }
}
